const objectToArrayConvetor = <T>(data: { [key: string]: unknown }) => {
  const arrData: T[] = [];

  for (const id in data) {
    arrData.push(Object.assign(data[id], { id }) as unknown as T);
  }

  return arrData;
};

export { objectToArrayConvetor };
