import React, { useCallback, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Container, Paper, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import DialogModal from "../../../components/DialogModal";
import validator from "validator";
import useFirebaseAuth from "../../../hooks/useFirebaseAuth";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { ADMIN_THEME } from "../../../util/theme";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: ADMIN_THEME.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      title: {
        color: ADMIN_THEME.titleTextColor
      },
      text: {
        color: theme.palette.text.primary
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: ADMIN_THEME.textField.backgroundColor,
        color: ADMIN_THEME.textField.color
      },
      textFieldIcon: {
        color: ADMIN_THEME.iconColor
      }
    })
  );

const ChangePassword: React.FC = () => {
  const styles = useStyles()();

  const { auth } = useFirebaseAuth();

  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const textFieldValueChanged = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "currentPassword":
        setCurrentPassword(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (!validator.isByteLength(currentPassword, { min: 6 })) {
      setDialogOptions({ open: true, body: "Current password entered should be atleast 6 characters in length" });
    } else if (!validator.isByteLength(password, { min: 6 })) {
      setDialogOptions({ open: true, body: "New password entered should be atleast 6 characters in length" });
    } else if (!validator.isByteLength(confirmPassword, { min: 6 })) {
      setDialogOptions({ open: true, body: "Confirm password entered should be atleast 6 characters in length" });
    } else if (password !== confirmPassword) {
      setDialogOptions({ open: true, body: "New password and confirmation are not the same" });
    } else if (currentPassword === password) {
      setDialogOptions({ open: true, body: "The new password should be diffrent from the current password" });
    } else {
      changePassword();
    }
  };

  const changePassword = useCallback(async () => {
    try {
      setLoading(true);
      const user = auth.currentUser;
      if (user) {
        await reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email ?? "", currentPassword));
        await updatePassword(user, password);
        setDialogOptions({ open: true, body: "Your password has been changed successfully" });
        setCurrentPassword("");
        setPassword("");
        setConfirmPassword("");
        return;
      }
      throw new Error("No user is currently logged in");
    } catch (error) {
      setDialogOptions({ open: true, body: (error as Error).message });
    } finally {
      setLoading(false);
    }
  }, [auth, currentPassword, password]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={() => setDialogOptions({ open: false, body: "" })} />
      <Container maxWidth="sm">
        <Grid container alignItems="center" style={{ minHeight: "85vh" }}>
          <Grid item xs={11}>
            <Paper className={styles.paper}>
              <form
                style={{ width: "100%" }}
                onSubmit={(event) => {
                  event.preventDefault();
                  submitForm();
                }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" align="center" className={styles.title}>
                      Change Authentication Password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center" className={styles.text}>
                      Enter the new password you wish to use to authenticate to your account
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="password"
                      value={currentPassword}
                      onChange={(event) => {
                        textFieldValueChanged("currentPassword", event.target.value);
                      }}
                      placeholder="Current Password (Required)"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="password"
                      value={password}
                      onChange={(event) => {
                        textFieldValueChanged("password", event.target.value);
                      }}
                      placeholder="New Password (Required)"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="password"
                      value={confirmPassword}
                      onChange={(event) => {
                        textFieldValueChanged("confirmPassword", event.target.value);
                      }}
                      placeholder="Confirm Password (Required)"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
                      {loadingIndicator}Change Password
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ChangePassword;
