import React from "react";
import { createTheme, ThemeOptions, ThemeProvider } from "@material-ui/core/styles";
import { Redirect, Route, Switch } from "react-router-dom";
import NavBar from "./navigation/NavBar";
import Footer from "./navigation/Footer";
import IndexContainer from "./containers/Index/Index";
import BlogContainer from "./containers/Articles/Blog";
import BlogArticleContainer from "./containers/Articles/Article/Article";
import Error404Container from "./containers/Error404";
import AccountLoginContainer from "./containers/Account/LogIn";
import AccountForgotContainer from "./containers/Account/Forgot";
import DashboardContainer from "./containers/Dashboard/Dashboard";
import { Alert, AlertTitle } from "@material-ui/lab";
import useFirebaseDB from "./hooks/useFirebaseDB";
import useFirebaseAuth from "./hooks/useFirebaseAuth";

interface AppInterface {
  isAdmin?: boolean;
}
const App: React.FC<AppInterface> = ({ isAdmin }) => {
  const { isConnected, isReConnected } = useFirebaseDB();

  const { loggedIn } = useFirebaseAuth();

  const theme: ThemeOptions = {
    typography: {
      fontFamily: `${"Poppins"}, Arial, sans-serif`
    },
    palette: {
      type: "light",
      background: {
        default: "#121212"
      }
    }
  };

  return (
    <ThemeProvider theme={createTheme(theme)}>
      {isReConnected && (
        <Alert severity="success">
          <AlertTitle>Network Connection Restored</AlertTitle>
          Your internet connection has been restored successfully
        </Alert>
      )}
      {!isConnected && (
        <Alert severity="error">
          <AlertTitle>No Network Connection</AlertTitle>
          Your internet connection is unstable or disconnected
        </Alert>
      )}
      {!loggedIn && <NavBar isAdmin={isAdmin} />}
      {isAdmin ? (
        <div style={{ backgroundColor: "#e4e6eb", minHeight: "100vh" }}>
          <title>Admin Dashboard | Account Login</title>
          <meta name="description" content="Admin dashboard for managing Purity International Consultancy LTD website" />
          <Switch>
            <Route exact path="/">
              {loggedIn ? <Redirect to="/dashboard" /> : <Redirect to="/account/login" />}
            </Route>
            <Route exact path="/account/login" component={AccountLoginContainer} />
            <Route exact path="/account/forgot" component={AccountForgotContainer} />
            <Route path="/dashboard" component={DashboardContainer} />
            <Route component={Error404Container} />
          </Switch>
        </div>
      ) : (
        <Switch>
          <Route exact path="/" component={IndexContainer} />
          <Route exact path="/blog" component={BlogContainer} />
          <Route exact path="/blog/category/:category_id" component={BlogContainer} />
          <Route exact path="/blog/:category_id/:article_name/:article_id" component={BlogArticleContainer} />
          <Route component={Error404Container} />
        </Switch>
      )}
      {!isAdmin && <Footer />}
    </ThemeProvider>
  );
};

export default App;
