import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const useFirebaseAuth = () => {
  const auth = getAuth();

  const [loggedIn, setLoggedIn] = useState<boolean>(auth.currentUser ? true : false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) return setLoggedIn(true);
      setLoggedIn(false);
    });
  }, [auth]);

  return {
    auth,
    loggedIn
  };
};

export default useFirebaseAuth;
