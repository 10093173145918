import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import WhatIDo from "./Sections/WhatIDo";
import AboutMe from "./Sections/AboutMe";

const Index: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (!location.hash) {
      return;
    }

    let elementId: string = "";

    switch (location.hash) {
      case "#home":
        elementId = "home";
        break;
      case "#about-me":
        elementId = "about-me";
        break;
      case "#what-we-do":
        elementId = "what-we-do";
        break;
      default:
        break;
    }

    if (elementId) {
      document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }, [location.hash]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Purity International Consultancy LTD</title>
        <meta name="description" content="We offer life coaching for your health and fitness, life vision, spiritual life, career, emotional & relationship life and much more" />
        <link rel="canonical" href={window.location.origin} />
        <meta property="og:site_name" content="Purity International Consultancy LTD" />
        <meta property="og:title" content="Purity International Consultancy LTD" />
        <meta property="og:description" content="We offer life coaching for your health and fitness, life vision, spiritual life, career, emotional & relationship life and much more" />
        <meta property="og:url" content={window.location.origin} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={`${window.location.origin}/home.jpeg`} />
      </Helmet>
      <AboutMe />
      <WhatIDo />
    </React.Fragment>
  );
};

export default Index;
