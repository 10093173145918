import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Container, Paper, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Lock, Mail } from "@material-ui/icons";
import DialogModal from "../../components/DialogModal";
import validator from "validator";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";
import { setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";
import { ADMIN_THEME } from "../../util/theme";
import qs from "query-string";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: ADMIN_THEME.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      title: {
        color: ADMIN_THEME.titleTextColor
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: ADMIN_THEME.textField.backgroundColor,
        color: ADMIN_THEME.textField.color
      },
      textFieldIcon: {
        color: ADMIN_THEME.iconColor
      }
    })
  );

const LogIn: React.FC = () => {
  const history = useHistory();

  const styles = useStyles()();

  const { auth, loggedIn } = useFirebaseAuth();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const textFieldValueChanged = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (!validator.isEmail(email)) {
      setDialogOptions({ open: true, body: "Email address entered is not valid" });
    } else if (!validator.isByteLength(password, { min: 6 })) {
      setDialogOptions({ open: true, body: "Password entered should be atleast 6 characters in length" });
    } else {
      logIn();
    }
  };

  const logIn = useCallback(async () => {
    try {
      setLoading(true);
      await setPersistence(auth, browserSessionPersistence);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setDialogOptions({ open: true, body: (error as Error).message });
    } finally {
      setLoading(false);
    }
  }, [auth, email, password]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const params = qs.parse(history.location.search);

      if (params.continueTo) {
        history.push(params.continueTo.toString());
        return;
      }

      history.push("/");
    }
  }, [loggedIn, history]);

  useEffect(() => {
    if (history.location.hash === "#logged-out-successfully") {
      setDialogOptions({ open: true, body: "You have been logged out successfully. Please log in back into your account to access it" });
    }

    if (history.location.hash === "#check-email-for-reset-password-link") {
      setDialogOptions({ open: true, body: "Password reset successfully. You can now log in with your new password" });
    }
  }, [history, history.location.hash]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={() => setDialogOptions({ open: false, body: "" })} />
      <Container maxWidth="sm">
        <Grid container alignItems="center" style={{ minHeight: "85vh" }}>
          <Grid item xs={11}>
            <Paper className={styles.paper}>
              <form
                style={{ width: "100%" }}
                onSubmit={(event) => {
                  event.preventDefault();
                  submitForm();
                }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" align="center" className={styles.title}>
                      Sign In to Admin Dashboard
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="email"
                      value={email}
                      onChange={(event) => {
                        textFieldValueChanged("email", event.target.value);
                      }}
                      placeholder="Email Address"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Mail className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="password"
                      value={password}
                      onChange={(event) => {
                        textFieldValueChanged("password", event.target.value);
                      }}
                      placeholder="Password"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
                      {loadingIndicator}Sign In
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} component={Link} to="/account/forgot">
                      Forgot Password
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default LogIn;
