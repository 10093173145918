import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress, Select, MenuItem } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import validator from "validator";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ADMIN_THEME } from "../../../../util/theme";
import BlogArticleInterface from "../../../../interfaces/entities/BlogArticleInterface";
import useFirebaseDB from "../../../../hooks/useFirebaseDB";
import BlogCategoryInterface from "../../../../interfaces/entities/BlogCategoryInterface";
import { ref, onValue, set } from "firebase/database";
import { objectToArrayConvetor } from "../../../../util/firebase-object-convertor";
import { getStorage, uploadString, ref as storageRef, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";

interface UpsertBlogDetailsFormProps {
  article: BlogArticleInterface | null;
  onDismissed: () => void;
}

interface UpsertBlogDetailsModalProps {
  open: boolean;
  article: BlogArticleInterface | null;
  onDismissed: () => void;
}

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: ADMIN_THEME.textField.backgroundColor,
        color: ADMIN_THEME.textField.color
      },
      textFieldIcon: {
        color: ADMIN_THEME.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto",
        objectFit: "contain"
      }
    })
  );

const UpsertBlogDetailsForm: React.FC<UpsertBlogDetailsFormProps> = (props) => {
  const styles = useStyles()();

  const { db } = useFirebaseDB();
  const history = useHistory();

  const storage = getStorage();

  const [id] = useState<string>(props.article?.id ?? uuidv4());
  const [title, setTitle] = useState<string>(props.article?.title ?? "");
  const [content, setContent] = useState<string>("");
  const [image, setImage] = useState<string>(props.article?.image ?? "");
  const [category, setCategory] = useState<string>(props.article?.category ?? "");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [categories, setCategories] = useState<Array<BlogCategoryInterface>>([]);

  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const modules = {
    toolbar: {
      container: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ["bold", "italic", "underline", "strike", "blockquote", "alignment"], [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }], ["link", "image"], ["clean"]]
    }
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"];

  useEffect(() => {
    onValue(ref(db, "blog/categories"), (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCategories(objectToArrayConvetor<BlogCategoryInterface>(data));
      } else {
        setCategories([]);
      }
      setCategoriesLoading(false);
    });

    onValue(ref(db, `blog/content/${id}`), (snapshot) => {
      const data = snapshot.val();
      if (data && data["value"]) {
        setContent(data["value"]);
      } else {
        setContent("");
      }
      setContentLoading(false);
    });
  }, [db, id]);

  const textFieldValueChanged = async (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "title":
        setTitle(value);
        break;
      case "content":
        setContent(value);
        break;
      case "category":
        setCategory(value);
        break;
      default:
        break;
    }
  };

  const uploadImage = async (fileExt: string, fileContent: string): Promise<string | Error> => {
    try {
      setFileUploading(true);

      const uploadRef = storageRef(storage, `article/images/${uuidv4()}.${fileExt}`);

      const snapshot = await uploadString(uploadRef, fileContent, "data_url");

      return await getDownloadURL(snapshot.ref);
    } catch (error) {
      return error as Error;
    } finally {
      setFileUploading(false);
    }
  };

  const submitForm = async () => {
    try {
      if (validator.isEmpty(title)) {
        setErrorMessage("The article title entered is not valid");
      } else if (validator.isEmpty(category)) {
        setErrorMessage("The article category entered is not valid");
      } else if (validator.isEmpty(image)) {
        setErrorMessage("The article cover image entered is not valid");
      } else if (validator.isEmpty(content)) {
        setErrorMessage("The article content entered is not valid");
      } else {
        setErrorMessage("");

        setFormSubmitted(true);

        let parsedContent = content;

        const images = content.split(`<img src="data:`);

        for (let i = 0; i < images.length; i++) {
          if (i === 0) {
            continue;
          }
          const [fileType, fileData] = images[i].split('"').shift()?.split(";") ?? ["", ""];
          const ext = fileType.split("/").pop();
          const url = await uploadImage(ext ?? "png", `data:${fileType};${fileData}`);
          if (!(url instanceof Error)) parsedContent = parsedContent.replace(`<img src="data:${fileType};${fileData}`, `<img src="${url}`);
        }

        const plainTextContent = content.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 50);

        if (props.article) {
          await Promise.all([
            set(ref(db, `blog/content/${id}`), {
              value: parsedContent
            }),
            set(ref(db, `blog/articles/${id}`), {
              ...props.article,
              title,
              image,
              category,
              content: plainTextContent,
              timestamp_modified: new Date().getTime()
            })
          ]);

          history.push(`/dashboard/articles#added-${id}`);
        } else {
          await Promise.all([
            set(ref(db, `blog/content/${id}`), {
              value: parsedContent
            }),
            set(ref(db, `blog/articles/${id}`), {
              title,
              image,
              category,
              content: plainTextContent,
              views: {
                default: 1
              },
              timestamp: new Date().getTime(),
              timestamp_modified: new Date().getTime()
            })
          ]);

          history.push(`/dashboard/articles#edited-${id}`);
        }

        setFormSubmitted(false);
        props.onDismissed();
      }
    } catch (error) {
      setErrorMessage("Error occurred: " + (error as Error).message);
    }
  };

  let loadingIndicator = null;

  const loading: boolean = categoriesLoading || contentLoading || fileUploading || formSubmitted;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={title}
            onChange={(event) => {
              textFieldValueChanged("title", event.target.value);
            }}
            placeholder="Title (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Category (Required)
          </Typography>
          <Select
            required
            value={category}
            input={
              <InputBase
                placeholder="Category (Required)"
                className={styles.textField}
                startAdornment={
                  <InputAdornment position="start">
                    <Create className={styles.textFieldIcon} />
                  </InputAdornment>
                }
              />
            }
            onChange={(event) => {
              textFieldValueChanged("category", event.target.value as string);
            }}>
            {categories.map((element, index) => (
              <MenuItem key={index} value={element.id}>
                {element.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          {image ? (
            <img className={styles.img} alt={title} src={image} />
          ) : (
            <Typography variant="body1" align="center" color="error">
              No Cover Image Uploaded Yet
            </Typography>
          )}
          <br />
          <Button disabled={loading} fullWidth color="primary" size="large" component="label">
            <input
              onChange={(event) => {
                if (event.target.files && event.target.files.length > 0) {
                  const file = event.target.files[0];
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = async () => {
                    const ext = file.type.split("/").pop();
                    const url = await uploadImage(ext ?? "", reader.result?.toString() ?? "");
                    if (url instanceof Error) {
                      setErrorMessage("An error occurred while uploading the image. Error: " + url.message);
                    } else {
                      setImage(url);
                    }
                  };
                }
              }}
              type="file"
              hidden
              accept="image/*"
            />
            {loadingIndicator} {image ? "Replace Blog Cover Image" : "Upload Blog Cover Image"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Typography variant="body1" align="center" color="error">
              Updating. Please Wait ...
            </Typography>
          )}
          <ReactQuill readOnly={loading} onChange={(text) => textFieldValueChanged("content", text)} value={content} theme="snow" modules={modules} formats={formats}></ReactQuill>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.article ? "Update Article Details" : "Add New Article"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const UpsertBlogDetailsModal: React.FC<UpsertBlogDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title={props.article ? "Update Article Details" : "Add New Article"}
      component={<UpsertBlogDetailsForm article={props.article} onDismissed={props.onDismissed} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertBlogDetailsModal;
