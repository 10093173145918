const ADMIN_THEME = {
  hoverColor: "#f0f2f5",
  emptyListTextColor: "#8a8d91",
  titleTextColor: "#050505",
  subTitleTextColor: "#b0b3b8",
  tabColor: "#65676b",
  iconColor: "#65676b",
  iconBackgroundColor: "#f0f2f5",
  iconBackgroundHoverColor: "#dee0e2",
  textField: {
    backgroundColor: "#f0f2f5",
    color: "#050505"
  },
  section: {
    backgroundColor: "#fff"
  }
};

export { ADMIN_THEME };
