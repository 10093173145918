import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Breadcrumbs, Card, CardActionArea, CardContent, CardMedia, Container, Divider, Grid, Hidden, List, ListItem, ListItemText, Paper, Typography } from "@material-ui/core";
import { ref, onValue, set } from "firebase/database";
import BlogCategoryInterface from "../../../interfaces/entities/BlogCategoryInterface";
import { Helmet } from "react-helmet";
import useFirebaseDB from "../../../hooks/useFirebaseDB";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import BlogArticleInterface from "../../../interfaces/entities/BlogArticleInterface";
import { objectToArrayConvetor } from "../../../util/firebase-object-convertor";
import dateformat from "dateformat";
import { makeFirstLetterInEveryWordUpperCase } from "../../../util/title-formater";
import Error404 from "../../Error404";
import { Visibility } from "@material-ui/icons";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: "#f4f6f7",
        paddingTop: 40
      },
      title: {
        color: alpha(theme.palette.common.black, 0.5),
        fontSize: "2rem",
        fontWeight: 700,
        marginBottom: 30
      },
      categoryTitle: {
        color: theme.palette.common.black,
        marginBottom: 30,
        fontSize: "2rem"
      },
      categoryPaper: {
        width: "100%",
        backgroundColor: "#e2dbd1",
        marginBottom: 30
      },
      categoryList: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          height: 350,
          overflow: "scroll"
        }
      },
      categoryListText: {
        color: alpha(theme.palette.common.black, 0.5)
      },
      categoryDivider: {
        backgroundColor: alpha(theme.palette.common.black, 0.25)
      },
      categoryLink: {
        textDecoration: "none"
      },
      card: {
        width: "100%",
        maxWidth: "100%",
        marginBottom: 30
      },
      paperSkeleton: {
        marginBottom: 30
      },
      articleText: {
        lineHeight: "1.5em",
        fontSize: "1.25em"
      },
      breadcrumbLink: {
        textDecoration: "none",
        color: "#2f89fc",
        "&:hover": {
          color: alpha("#2f89fc", 0.5)
        }
      },
      icon: {
        color: "#2f89fc"
      }
    })
  );

const Article: React.FC = () => {
  const styles = useStyles()();

  const { category_id, article_name, article_id } = useParams<{ category_id: string; article_name: string; article_id: string }>();

  const { db } = useFirebaseDB();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [article, setArticle] = useState<BlogArticleInterface>();
  const [content, setContent] = useState<string>();
  const [categories, setCategories] = useState<Array<BlogCategoryInterface>>([]);

  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);
  const [articleLoading, setArticleLoading] = useState<boolean>(true);
  const [contentLoading, setContentLoading] = useState<boolean>(true);

  useEffect(() => {
    if (article && content) {
      set(ref(db, `blog/articles/${article_id}/views/${localStorage.getItem("udid") ?? "default"}`), 1);
    }
  }, [db, article, article_id, content]);

  useEffect(() => {
    onValue(ref(db, "blog/categories"), (snapshot) => {
      const data = snapshot.val();

      if (data) {
        setCategories(objectToArrayConvetor<BlogCategoryInterface>(data));
      } else {
        setCategories([]);
      }

      setCategoriesLoading(false);
    });

    onValue(ref(db, `blog/articles/${article_id}`), (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setArticle(data);
      } else {
        setArticle(undefined);
      }
      setArticleLoading(false);
    });

    onValue(ref(db, `blog/content/${article_id}`), (snapshot) => {
      const data = snapshot.val();
      if (data && data["value"]) {
        setContent(data["value"].replace(/<img/g, '<img width="100%"'));
      } else {
        setContent(undefined);
      }
      setContentLoading(false);
    });
  }, [db, article_id]);

  const category = category_id.split("-").join(" ");

  const categoryTitle = makeFirstLetterInEveryWordUpperCase(category);

  const articleTitle = makeFirstLetterInEveryWordUpperCase(article_name.split("-").join(" "));

  if (!articleLoading && !contentLoading && (!article || !content)) {
    return <Error404 />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{articleTitle}</title>
        <meta name="description" content={article?.content ?? ""} />
        <link rel="canonical" href={window.location.origin + "/blog/article/" + article_name + "/" + article_id} />
        <meta property="og:title" content={articleTitle} />
        <meta property="og:site_name" content="Purity International Consultancy LTD" />
        <meta property="og:description" content={article?.content ?? ""} />
        <meta property="og:url" content={window.location.origin + "/blog/" + category_id + "/" + article_name + "/" + article_id} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={article?.image ?? ""} />
        <meta property="article:author" content={"Purity Tiedora Mbanda Achero"} />
        <meta property="article:section" content={categoryTitle} />
        <meta property="article:tag" content={categoryTitle} />
        <meta property="article:published_time" content={new Date(article?.timestamp ?? 0).toISOString()} />
        <meta property="article:modified_time " content={new Date(article?.timestamp_modified ?? 0).toISOString()} />
      </Helmet>
      <div className={styles.root}>
        <Container maxWidth="lg">
          <Typography variant="h2" className={styles.title}>
            {articleTitle}
          </Typography>
          <Breadcrumbs>
            <Link color="inherit" to="/" className={styles.breadcrumbLink}>
              Home
            </Link>
            <Link color="inherit" to="/blog" className={styles.breadcrumbLink}>
              Blog
            </Link>
            <Link color="inherit" to={`/blog/category/${article?.category ?? ""}`} className={styles.breadcrumbLink}>
              {categoryTitle}
            </Link>
            <Typography color="textPrimary">{articleTitle}</Typography>
          </Breadcrumbs>
          <br />
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={9}>
              {articleLoading ? (
                <Paper className={styles.paperSkeleton}>
                  <Skeleton style={{ marginBottom: 25 }} variant="rect" height={350} />
                  <div style={{ padding: 15 }}>
                    <Skeleton style={{ marginBottom: 25 }} variant="text" width="25%" />
                    <Skeleton style={{ marginBottom: 25 }} variant="rect" height={20} />
                    <Skeleton style={{ marginBottom: 5 }} variant="text" />
                    <Skeleton style={{ marginBottom: 5 }} variant="text" />
                    <Skeleton style={{ marginBottom: 5 }} variant="text" />
                    <Skeleton style={{ marginBottom: 5 }} variant="text" />
                  </div>
                </Paper>
              ) : (
                <Card className={styles.card}>
                  <CardActionArea>
                    <CardMedia component="img" alt={articleTitle} height="350" image={article?.image ?? ""} title={articleTitle} />
                    <CardContent>
                      <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item>
                          <Typography variant="caption" color="textSecondary">
                            {dateformat(article?.timestamp ?? 0, "ddd mmm dd yyyy")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item>
                              <Visibility className={styles.icon} />
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" color="textSecondary">
                                {Object.keys(article?.views ?? { default: 1 }).length - 1}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <br />
                      <Typography variant="body2" color="textSecondary" className={styles.articleText} dangerouslySetInnerHTML={{ __html: content ?? "" }} />
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} sm={12} md={3}>
                <Paper className={styles.categoryPaper}>
                  <Typography variant="h4" className={styles.categoryTitle} align="center">
                    Categories
                  </Typography>
                  <Divider className={styles.categoryDivider} />
                  {categoriesLoading ? (
                    <React.Fragment>
                      {new Array(7).fill(null).map((_, index) => (
                        <Skeleton style={{ marginTop: 25, marginBottom: 25, marginLeft: 10, marginRight: 10 }} key={index} variant="rect" height={30} />
                      ))}
                      <br />
                    </React.Fragment>
                  ) : (
                    <List className={styles.categoryList}>
                      {categories.map((category, index) => (
                        <Link className={styles.categoryLink} key={index} to={`/blog/category/${category.id}`}>
                          <ListItem button className={styles.categoryListText}>
                            <ListItemText primary={category.name} />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  )}
                </Paper>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Article;
