import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Box, Button, Container, Grid, Typography } from "@material-ui/core";
import FitnessLifeImg from "../../../assets/fitnessLife.jpeg";
import LifeVisionImg from "../../../assets/lifeVision.jpeg";
import ParentingImg from "../../../assets/parenting.jpeg";
import CharacterLifeImg from "../../../assets/characterLife.jpeg";
import QualityLifeImg from "../../../assets/qualityLife.jpg";
import FinancialLifeImg from "../../../assets/financialLife.jpeg";
import SocialLifeImg from "../../../assets/socialLife.jpeg";
import InterllectualLifeImg from "../../../assets/interllectualLife.jpeg";
import RelationshipLifeImg from "../../../assets/relationshipLife.jpeg";
import EmotionalLifeImg from "../../../assets/emotionalLife.jpeg";
import CareerLifeImg from "../../../assets/careerLife.jpeg";
import SpiritualLifeImg from "../../../assets/spiritualLife.jpeg";
import { Link } from "react-router-dom";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        paddingTop: 70,
        backgroundColor: alpha(theme.palette.background.default, 0.325),
        overflowX: "hidden"
      },
      gridWhite: {
        backgroundColor: "#e2dbd1"
      },
      gridDark: {
        backgroundColor: alpha(theme.palette.background.default, 0.75)
      },
      titleWhite: {
        color: theme.palette.common.white,
        marginBottom: 30,
        fontSize: "2rem",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center"
        }
      },
      subTitleWhite: {
        color: alpha(theme.palette.common.white, 0.5),
        [theme.breakpoints.down("sm")]: {
          textAlign: "center"
        }
      },
      textWhite: {
        color: alpha(theme.palette.common.white, 0.5),
        wordWrap: "break-word",
        fontSize: 18,
        lineHeight: 2,
        display: "block"
      },
      titleDark: {
        color: theme.palette.common.black,
        marginBottom: 30,
        fontSize: "2rem",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center"
        }
      },
      subTitleDark: {
        color: alpha(theme.palette.common.black, 0.5),
        [theme.breakpoints.down("sm")]: {
          textAlign: "center"
        }
      },
      textDark: {
        color: alpha(theme.palette.common.black, 0.5),
        wordWrap: "break-word",
        fontSize: 18,
        lineHeight: 2,
        display: "block"
      },
      img: {
        width: "100%",
        height: 450,
        objectFit: "contain",
        [theme.breakpoints.down("sm")]: {
          height: 250,
          objectFit: "contain"
        }
      },
      button: {
        backgroundColor: "#2f89fc",
        color: theme.palette.common.white,
        textTransform: "none",
        width: "50%",
        "&:hover": {
          backgroundColor: alpha("#2f89fc", 0.9)
        }
      }
    })
  );

const WhatIDo: React.FC = () => {
  const styles = useStyles()();
  const categories = [
    {
      src: FitnessLifeImg,
      title: "True fitness is about feeling healthy",
      subtitle: "YOUR HEALTH AND FITNESS",
      text: "True fitness is about feeling healthy and being in sufficient shape to do the activities you want to do and live the lifestyle you want to live.",
      category: "health-and-fitness"
    },
    {
      src: LifeVisionImg,
      title: "Vision is the act or power which gives a direction to our life",
      subtitle: "YOUR LIFE VISION",
      text: "Vision is the act or power which gives a direction to our life to make our goals and dreams a reality A vision and a purpose helps makeour decisions that create our lifestyle.",
      category: "life-vision"
    },
    {
      src: SpiritualLifeImg,
      title: "Religion and spirituality are not the same thing, nor are they entirely distinct from one another",
      subtitle: "YOUR SPIRITUAL LIFE",
      text: "While spirituality may incorporate elements of religion, it is generally a broader concept. Religion and spirituality are not the same thing, nor are they entirely distinct from one another.",
      category: "spiritual-life"
    },
    {
      src: CareerLifeImg,
      title: "Change is unavoidable in life. It is what move us forward",
      subtitle: "YOUR CAREER LIFE",
      text: "Change is unavoidable in life. It is what move us forward, toward bigger and better things. Many people are able to take change in stride and adjust to it well.",
      category: "career-life"
    },
    {
      src: EmotionalLifeImg,
      title: "Did you know that it’s just as important to process your emotions as it is to process your meals?",
      subtitle: "YOUR EMOTIONAL LIFE",
      text: "Did you know that it’s just as important to process your emotions as it is to process your meals? When you don’t process your food properly, your body accumulates toxins that leads to illnesses. When you fail to process your emotions, you create toxins of another sort. Emotional toxins manifest as anxiety, depression, sadness, hopelessness, anger, rage, impatience, or guilt.",
      category: "emotional-life"
    },
    {
      src: RelationshipLifeImg,
      title: "Self-love and acceptance are the seeds to fulfilling, long-lasting relationships",
      subtitle: "YOUR RELATIONSHIP LIFE",
      text: "Self-love and acceptance are the seeds to fulfilling, long-lasting relationships. Vironika Tugaleva said that our friends are a reflection of how we view ourselves. There is that saying that you are the average of the five people you surround yourself with.",
      category: "relationship-life"
    },
    {
      src: InterllectualLifeImg,
      title: "Do I have a robust understanding of the world around me?",
      subtitle: "YOUR INTELLECTUAL LIFE",
      text: "One’s intellectual life comes down to a positive answer to one key question: do I have a robust understanding of the world around me which I can use to solve problems and relate to others?",
      category: "interllectual-life"
    },
    {
      src: SocialLifeImg,
      title: "Human beings are social animals",
      subtitle: "YOUR SOCIAL LIFE",
      text: "Human beings are social animals, and the tenor of someones social life is one of the most important influences on their mental and physical health. Without positive, durable relationships, both minds and bodies can fall apart.",
      category: "social-life"
    },
    {
      src: FinancialLifeImg,
      title: "When you feel in control of your money, it’s a lot easier to make smart financial decisions",
      subtitle: "YOUR FINANCIAL LIFE",
      text: "When you feel in control of your money, it’s a lot easier to make smart financial decisions. This is easier said than done, but if you divide the process into actionable steps, you’ll see that your goals are within reach.",
      category: "financial-life"
    },
    {
      src: QualityLifeImg,
      title: "Quality of life is a non – financial component associated with job and life satisfaction",
      subtitle: "YOUR QUALITY OF LIFE",
      text: "Quality of life is a non – financial component associated with job and life satisfaction. When used in a work-related way, quality of life often refers to the time and ability to do the thing you enjoy. If a job pays a lot of money but requires so many working hours that the worker cannot enjoy any of the money earned, that is a poor quality of life.",
      category: "quality-of-life"
    },
    {
      src: CharacterLifeImg,
      title: "Personal development is really a single problem",
      subtitle: "YOUR CHARACTER LIFE",
      text: "Personal development is really a single problem. We have an idea of a life we want to live and personal development is the act of bringing that life into being. When we look at personal development we see it as actions we need to take in the present that will affect outcomes in the future.",
      category: "character-life"
    },
    {
      src: ParentingImg,
      title: "The impact of parents may never be greater than during the earliest years of life",
      subtitle: "YOUR PARENTING LIFE",
      text: "The impact of parents may never be greater than during the earliest years of life, when a child’s brain is rapidly developing and when nearly all of her or his experiences are created and shaped by parents and the family environment. Parents help children build and refine their knowledge and skills, charting a trajectory for their health and well-being during childhood and beyond.",
      category: "parenting-life"
    }
  ];

  return (
    <div id="what-we-do" className={styles.root}>
      <Grid container direction="column" spacing={5}>
        {categories.map((element, index) => {
          return (
            <Grid item key={index} className={index % 2 === 0 ? styles.gridDark : styles.gridWhite}>
              {index === 0 && (
                <React.Fragment>
                  <Typography variant="subtitle1" className={styles.subTitleWhite} align="center">
                    WHAT SHE DOES
                  </Typography>
                  <Typography variant="h2" className={styles.titleWhite} style={{ fontWeight: "bold" }} align="center">
                    Areas she covers as a life coach
                  </Typography>
                </React.Fragment>
              )}
              <Container maxWidth="md">
                <Grid container alignContent="space-between" spacing={5} direction={index % 2 === 0 ? "row" : "row-reverse"}>
                  <Grid item xs={12} sm={12} md={6}>
                    <img src={element.src} className={styles.img} alt={element.title} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Typography variant="subtitle1" className={index % 2 === 0 ? styles.subTitleWhite : styles.subTitleDark}>
                          {element.subtitle}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h3" className={index % 2 === 0 ? styles.titleWhite : styles.titleDark}>
                          {element.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className={index % 2 === 0 ? styles.textWhite : styles.textDark} align="justify">
                          {element.text}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box textAlign="center">
                          <Button size="large" variant="contained" className={styles.button} component={Link} to={`/blog/category/${element.category}`}>
                            Read More
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default WhatIDo;
