import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { initializeApp } from "firebase/app";
import { v4 as uuidv4 } from "uuid";

const udid: string = localStorage.getItem("udid") ?? uuidv4();

if (udid) {
  localStorage.setItem("udid", udid);
}

const isAdmin: boolean = process.env["REACT_APP_ADMIN_URLS"]?.split(",").includes(window.location.origin) ?? false;

const firebaseConfig = isAdmin
  ? {
      apiKey: "AIzaSyD4fc4p53FbKJIZE-v0MbAFMC786RZ1Dok",
      authDomain: "ps-coaching-center.firebaseapp.com",
      databaseURL: "https://ps-coaching-center-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "ps-coaching-center",
      storageBucket: "ps-coaching-center.appspot.com",
      messagingSenderId: "961019255050",
      appId: "1:961019255050:web:1da2e4c07c5027284582f3"
    }
  : {
      apiKey: "AIzaSyD4fc4p53FbKJIZE-v0MbAFMC786RZ1Dok",
      authDomain: "ps-coaching-center.firebaseapp.com",
      databaseURL: "https://ps-coaching-center-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "ps-coaching-center",
      storageBucket: "ps-coaching-center.appspot.com",
      messagingSenderId: "961019255050",
      appId: "1:961019255050:web:55ad99f23aa2f26b4582f3"
    };

initializeApp(firebaseConfig);

ReactDOM.render(
  <BrowserRouter>
    <App isAdmin={isAdmin} />
  </BrowserRouter>,
  document.getElementById("root")
);
