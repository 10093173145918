import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, AppBar, Button, Divider, Drawer, Grid, Hidden, IconButton, List, ListItem, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Menu, Phone } from "@material-ui/icons";

interface NavBarInterface {
  isAdmin?: boolean;
}

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: theme.palette.background.default,
        padding: 15
      },
      title: {
        textDecoration: "none",
        color: "inherit",
        marginTop: 12,
        fontSize: "1em"
      },
      navLink: {
        color: alpha(theme.palette.common.white, 0.5),
        "&:hover": {
          color: theme.palette.common.white
        }
      },
      sideLink: {
        color: "#2f89fc",
        "&:hover": {
          color: alpha("#2f89fc", 0.9)
        }
      },
      sideButton: {
        backgroundColor: "#2f89fc",
        color: theme.palette.common.white,
        textTransform: "none",
        "&:hover": {
          backgroundColor: alpha("#2f89fc", 0.9)
        }
      },
      drawerList: {
        width: 250,
        height: "100%",
        backgroundColor: theme.palette.background.default
      }
    })
  );

const NavBar: React.FC<NavBarInterface> = ({ isAdmin }) => {
  const styles = useStyles()();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const email: string = process.env["REACT_APP_CONTACT_EMAIL"] ?? "";

  const phone: string = process.env["REACT_APP_CONTACT_MOBILE_NO"] ?? "";

  const navItems = [
    { text: "Home", to: "/" },
    { text: "About Me", to: "/#about-me" },
    { text: "What We Do", to: "/#what-we-do" },
    { text: "Blog", to: "/blog" }
  ];

  return (
    <React.Fragment>
      {!isAdmin && (
        <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <div className={styles.drawerList} role="presentation" onClick={() => setDrawerOpen(false)} onKeyDown={() => setDrawerOpen(false)}>
            <Typography variant="h6" className={styles.title} align="center">
              Purity International Consultancy LTD
            </Typography>
            <br />
            <Divider />
            <List>
              {navItems.map((element, index) => (
                <ListItem key={index}>
                  <Button fullWidth size="large" component={Link} to={element.to} className={styles.navLink}>
                    {element.text}
                  </Button>
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      )}
      <AppBar position="sticky" className={styles.root}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Grid container spacing={3}>
              {!isAdmin && (
                <Hidden mdUp>
                  <Grid item>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
                      <Menu />
                    </IconButton>
                  </Grid>
                </Hidden>
              )}
              <Grid item>
                <Typography variant="h6" className={styles.title}>
                  Purity International Consultancy LTD
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!isAdmin && (
            <React.Fragment>
              <Hidden smDown>
                <Grid item>
                  {navItems.map((element, index) => (
                    <Button key={index} size="large" color="inherit" component={Link} to={element.to} className={styles.navLink}>
                      {element.text}
                    </Button>
                  ))}
                </Grid>
              </Hidden>
              <Hidden mdDown>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button startIcon={<Phone />} className={styles.sideLink} href={`tel:${phone.split(" ").join("")}`}>
                        {phone}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" className={styles.sideButton} href={`mailto:${email}`}>
                        {email}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </React.Fragment>
          )}
        </Grid>
      </AppBar>
    </React.Fragment>
  );
};

export default NavBar;
