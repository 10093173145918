import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Container, Grid, IconButton, Typography } from "@material-ui/core";
import { Facebook, Instagram, LinkedIn, Twitter, YouTube } from "@material-ui/icons";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        paddingTop: 100,
        paddingBottom: 100,
        backgroundColor: theme.palette.background.default
      },
      title: {
        color: theme.palette.common.white,
        fontSize: "2.125rem"
      },
      text: {
        color: alpha(theme.palette.common.white, 0.5),
        fontSize: 18,
        lineHeight: 2,
        display: "block"
      },
      icon: {
        backgroundColor: alpha(theme.palette.common.white, 0.05),
        color: alpha(theme.palette.common.white, 0.5),
        padding: 20,
        "&:hover": {
          color: theme.palette.common.white,
          backgroundColor: alpha(theme.palette.common.white, 0.25)
        }
      },
      link: {
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
          color: theme.palette.common.white
        }
      }
    })
  );

const Footer: React.FC = () => {
  const styles = useStyles()();

  const email: string = process.env["REACT_APP_CONTACT_EMAIL"] ?? "";

  const phone: string = process.env["REACT_APP_CONTACT_MOBILE_NO"] ?? "";

  return (
    <div className={styles.root}>
      <Container maxWidth="lg">
        <Grid container direction="column" alignContent="center" spacing={5}>
          <Grid item>
            <Grid container justifyContent="center" spacing={2}>
              {[
                { icon: <Twitter />, url: "https://twitter.com/purityroc4" },
                { icon: <Facebook />, url: "https://web.facebook.com/Lifecoach254/?_rdc=1&_rdr" },
                { icon: <Instagram />, url: "https://www.instagram.com/puritytiedora/" },
                { icon: <LinkedIn />, url: "https://ke.linkedin.com/in/purity-achero-2368aa196" },
                { icon: <YouTube />, url: "https://www.youtube.com/channel/UCKZsx5_MkuRVmJRYMLiObqA" }
              ].map((element, index) => (
                <Grid item key={index}>
                  <IconButton size="medium" className={styles.icon} target="_blank" href={element.url}>
                    {element.icon}
                  </IconButton>
                </Grid>
              ))}
            </Grid>
            <br />
            <br />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignContent="center" spacing={1}>
              <Grid item>
                <Typography variant="h2" className={styles.title} align="center">
                  Contact Me
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={styles.text} align="center">
                  Email:{" "}
                  <a href={`mailto:${email}`} className={styles.link}>
                    {email}
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={styles.text} align="center">
                  Call:{" "}
                  <a href={`tel:${phone.split(" ").join("")}`} className={styles.link}>
                    {phone}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <br />
            <br />
            <Typography variant="subtitle1" className={styles.text} align="center">{`Copyright ©${new Date().getFullYear()} All rights reserved`}</Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
