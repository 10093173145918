import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Container, Paper, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import DialogModal from "../../../components/DialogModal";
import PopupMenuDialog from "../../../components/PopupMenuDialog";
import UpsertArticleDetailsDialog from "./Modals/UpsertArticleDetailsDialog";
import dateformat from "dateformat";
import BlogArticleInterface from "../../../interfaces/entities/BlogArticleInterface";
import { ref, onValue, query, remove } from "firebase/database";
import { objectToArrayConvetor } from "../../../util/firebase-object-convertor";
import useFirebaseDB from "../../../hooks/useFirebaseDB";
import { ADMIN_THEME } from "../../../util/theme";
import { makeFirstLetterInEveryWordUpperCase } from "../../../util/title-formater";
import { useHistory } from "react-router-dom";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: ADMIN_THEME.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      container: {
        minHeight: "60vh"
      },
      tableHeaderCell: {
        backgroundColor: ADMIN_THEME.section.backgroundColor
      }
    })
  );

const Articles: React.FC = () => {
  const styles = useStyles()();

  const { db } = useFirebaseDB();
  const history = useHistory();

  const [page, setPage] = useState<number>(0);
  const [articles, setArticles] = useState<Array<BlogArticleInterface>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedRecord, setSelectedRecord] = useState<BlogArticleInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const [upsertArticleDetailsModelOpen, setUpsertArticleDetailsModelOpen] = useState<boolean>(false);
  const menuItems = useMemo(() => {
    return ["Update Article Details", "Delete Article"];
  }, []);

  useEffect(() => {
    onValue(query(ref(db, "blog/articles")), (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setArticles(objectToArrayConvetor<BlogArticleInterface>(data).reverse());
      } else {
        setArticles([]);
      }
      setLoading(false);
    });
  }, [db]);

  useEffect(() => {
    if (history.location.hash?.startsWith("added-")) {
      setDialogOptions({ open: true, body: `New Article with ID: ${history.location.hash.split("added-").pop()} has been added successfully` });
    }

    if (history.location.hash?.startsWith("edited-")) {
      setDialogOptions({ open: true, body: `Article with ID: ${history.location.hash.split("edited-").pop()} has been edited successfully` });
    }
  }, [history, history.location.hash]);

  let start = page * 20 - 1;

  if (page === 0) {
    start = 0;
  }

  let end = page * 20 + 19;

  if (page === 0) {
    end = 20;
  }

  const currentArticles = articles.slice(start, end);

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "Update Article Details":
        setUpsertArticleDetailsModelOpen(true);
        break;
      case "Delete Article":
        if (window.confirm("Would you like to delete this article?")) {
          deleteArticle();
        }
        break;
      default:
        break;
    }
  };

  const deleteArticle = useCallback(async () => {
    try {
      const id = selectedRecord?.id ?? "";
      await Promise.all([remove(ref(db, `blog/articles/${id}`)), remove(ref(db, `blog/content/${id}`))]);
      setDialogOptions({ open: true, body: "Article deleted successfully" });
    } catch (error) {
      setDialogOptions({ open: true, body: "An error occurred while deleting the article. Error: " + (error as Error).message });
    }
  }, [db, selectedRecord]);

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={() => setDialogOptions({ open: false, body: "" })} />
      <UpsertArticleDetailsDialog open={upsertArticleDetailsModelOpen} onDismissed={() => setUpsertArticleDetailsModelOpen(false)} article={selectedRecord} />
      <PopupMenuDialog open={popUpDialogOpen} onDismissed={() => setPopUpDialogOpen(false)} onMenuItemSelected={onMenuItemSelected} menuItems={menuItems} />
      <Container maxWidth="xl">
        <React.Fragment>
          <Paper className={styles.paper}>
            <Grid container justifyContent="space-between" spacing={0}>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>{loading && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}</Grid>
                  <Grid item>
                    <Typography variant="h3" color="primary">
                      Blogs
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <br />
            <TableContainer className={styles.container}>
              <Table stickyHeader aria-label="articles">
                <TableHead>
                  <TableRow>
                    {[
                      { label: "ID", minWidth: 100 },
                      { label: "Title", minWidth: 150 },
                      { label: "Category", minWidth: 150 },
                      { label: "Views", minWidth: 100 },
                      { label: "Created At", minWidth: 150 },
                      { label: "Last Modified At", minWidth: 150 },
                      { label: "Preview", minWidth: 200 },
                      { label: "Options", minWidth: 100 }
                    ].map((column) => (
                      <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }} className={styles.tableHeaderCell}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentArticles.map((article: BlogArticleInterface) => {
                    const id = article.id;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={id}>
                        {["id", "title", "category", "views", "timestamp", "timestamp_modified", "preview", "options"].map((column) => {
                          const key = `${id}-${column}`;
                          const data = (article as unknown as { [key: string]: any })[column];
                          if (column === "options") {
                            if (menuItems.length === 0) return <TableCell key={key} align="center"></TableCell>;
                            return (
                              <TableCell key={key} align="center">
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setSelectedRecord(article);
                                    setPopUpDialogOpen(true);
                                  }}>
                                  Options
                                </Button>
                              </TableCell>
                            );
                          } else if (column === "category") {
                            return (
                              <TableCell key={key} align="center">
                                {makeFirstLetterInEveryWordUpperCase(data?.split("-").join(" ") ?? "")}
                              </TableCell>
                            );
                          } else if (column === "views") {
                            return (
                              <TableCell key={key} align="center">
                                {Object.keys(data ?? { default: 1 }).length - 1}
                              </TableCell>
                            );
                          } else if (column === "timestamp") {
                            return (
                              <TableCell key={key} align="center">
                                {dateformat(data, "ddd mmm dd yyyy h:MM TT")}
                              </TableCell>
                            );
                          } else if (column === "timestamp_modified") {
                            return (
                              <TableCell key={key} align="center">
                                {dateformat(data, "ddd mmm dd yyyy h:MM TT")}
                              </TableCell>
                            );
                          } else if (column === "preview") {
                            return (
                              <TableCell key={key} align="center">
                                <Button
                                  variant="outlined"
                                  href={`${process.env["REACT_APP_INDEX_CONANICAL_URL"]}/blog/${article.category}/${article.title
                                    .replace(/[^a-zA-Z0-9 ]/g, "")
                                    .replace(/ /g, "-")
                                    .toLowerCase()}/${article.id}`}
                                  target="_blank">
                                  Preview Article
                                </Button>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={key} align="center">
                                {data}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
              <Grid item>
                <Button
                  disabled={loading}
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setSelectedRecord(null);
                    setUpsertArticleDetailsModelOpen(true);
                  }}>
                  Add New Article
                </Button>
              </Grid>
              <Grid item>
                <TablePagination component="div" count={articles.length} page={page} onPageChange={(_, page) => setPage(page)} rowsPerPage={20} rowsPerPageOptions={[20]} />
              </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
      </Container>
    </div>
  );
};

export default Articles;
