import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Container, Grid, IconButton, Typography } from "@material-ui/core";
import ProfileImg from "../../../assets/profile.png";
import { Facebook, Instagram, LinkedIn, Twitter, YouTube } from "@material-ui/icons";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: "#f4f6f7",
        paddingTop: 60
      },
      title: {
        color: alpha(theme.palette.common.black, 0.1),
        fontSize: "6rem",
        fontWeight: 700
      },
      subTitle: {
        color: theme.palette.common.black,
        fontSize: "2.125rem"
      },
      text: {
        color: alpha(theme.palette.common.black, 0.5),
        wordWrap: "break-word",
        fontSize: 18,
        lineHeight: 2,
        display: "block"
      },
      img: {
        width: "100%",
        height: 850,
        objectFit: "contain",
        [theme.breakpoints.down("sm")]: {
          height: 250,
          objectFit: "contain"
        }
      },
      icon: {
        backgroundColor: alpha(theme.palette.common.black, 0.05),
        color: "#2f89fc",
        padding: 20,
        "&:hover": {
          color: theme.palette.common.black,
          backgroundColor: alpha(theme.palette.common.black, 0.05)
        }
      },
      link: {
        textDecoration: "none",
        color: "#2f89fc",
        textAlign: "justify",
        "&:hover": {
          color: alpha("#2f89fc", 0.5)
        }
      }
    })
  );

const AboutMe: React.FC = () => {
  const styles = useStyles()();

  const email: string = process.env["REACT_APP_CONTACT_EMAIL"] ?? "";

  const phone: string = process.env["REACT_APP_CONTACT_MOBILE_NO"] ?? "";

  return (
    <div id="about-me" className={styles.root}>
      <Container maxWidth="lg">
        <Grid container alignContent="space-between" spacing={5}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <img src={ProfileImg} className={styles.img} alt="profile" />
              </Grid>
              <Grid item>
                <br />
                <Grid container spacing={2} justifyContent="center">
                  {[
                    { icon: <Twitter />, url: "https://twitter.com/purityroc4" },
                    { icon: <Facebook />, url: "https://web.facebook.com/Lifecoach254/?_rdc=1&_rdr" },
                    { icon: <Instagram />, url: "https://www.instagram.com/puritytiedora/" },
                    { icon: <LinkedIn />, url: "https://ke.linkedin.com/in/purity-achero-2368aa196" },
                    { icon: <YouTube />, url: "https://www.youtube.com/channel/UCKZsx5_MkuRVmJRYMLiObqA" }
                  ].map((element, index) => (
                    <Grid item key={index}>
                      <IconButton size="medium" className={styles.icon} target="_blank" href={element.url}>
                        {element.icon}
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <br />
                  <br />
                  <Typography variant="h6" className={styles.text}>
                    Contact me here!
                  </Typography>
                  <br />
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={styles.text}>
                    Email:{" "}
                    <a href={`mailto:${email}`} className={styles.link}>
                      {email}
                    </a>
                  </Typography>
                  <br />
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={styles.text}>
                    Call:{" "}
                    <a href={`tel:${phone.split(" ").join("")}`} className={styles.link}>
                      {phone}
                    </a>
                  </Typography>
                  <br />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h2" className={styles.title}>
                  ABOUT
                </Typography>
                <Typography variant="h3" className={styles.subTitle} align="center">
                  Hi! Meet Purity
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={styles.text} align="justify">
                  Purity Tiedora is the founder and CEO of Purity International Consulting LTD and a Coach at CECP. Purity Tiedora is a Life Coach and Image Consultant who is devoted to helping Professionals, Executives, Church Ministers and workers
                  to untangle the yarn of life, by pressing the reset button and opening them to a world of Emotional well-being, and a balanced mindset in life. Purity Tiedora is a Highly sort after Coach, public speaker, Motivator, Master of
                  ceremonies, Preacher of the word, and Singer of Gospel Songs by Organizations, Companies, Schools, Churches, and Individuals both Local and International.
                </Typography>
                <br />
                <Typography variant="body1" className={styles.text} align="justify">
                  Purity is a strong believer in giving everyone a chance to become, that no matter where you came from, you should never allow your past to define you. Instead, believe that you can untangle your yarn of life if you are willing and
                  crave for a better you in your tomorrow. Purity holds a Diploma in Management from The Kenya Institute of Management (KIM), Advanced Diploma in Biblical Studies from Biltmore Bible School of Theology (BBST), Certificate in Coaching
                  from The Center of Etiquette, Civility and Protocol (CECP), Certificate in Marketing Management from The European Business University (EBU), and currently undertaking a Diploma in Project Management from The European Business
                  University (EBU). She is also a family person, married to one man and God has blessed them with 6 beautiful children (4 boys and 2 girls).
                </Typography>
                <br />
                <Typography variant="body1" className={styles.text} align="justify">
                  Purity is the second borne in the family of 6 (3 boys and 3 girls). She is a Kenyan Citizen, born and raised in Lugulu Village, Butula Constituency, Busia County. When she is not on duty she enjoys cooking, reading, and series
                  movies. Her contribution to society, is inclusive of her service in church because of her Zeal, Passion, Love, Sacrifice, and Dedication to God and the body of Christ.
                </Typography>
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutMe;
