import React from "react";
import DialogModal from "./DialogModal";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";

interface PopupMenuDialogProps {
  open: boolean;
  menuItems: Array<string>;
  onDismissed: () => void;
  onMenuItemSelected: (menuItem: string) => void;
}

interface PopupMenuProps {
  menuItems: Array<string>;
  onMenuItemSelected: (menuItem: string) => void;
}

const PopupMenu: React.FC<PopupMenuProps> = (props) => {
  const { menuItems, onMenuItemSelected } = props;
  return (
    <List>
      {menuItems.map((menuItem) => (
        <React.Fragment key={menuItem}>
          <ListItem button onClick={() => onMenuItemSelected(menuItem)}>
            <ListItemText primary={menuItem} />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

const PopupMenuDialog: React.FC<PopupMenuDialogProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title="Select Option"
      component={<PopupMenu menuItems={props.menuItems} onMenuItemSelected={props.onMenuItemSelected} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default PopupMenuDialog;
