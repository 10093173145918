import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Breadcrumbs, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Divider, Grid, Hidden, List, ListItem, ListItemText, Paper, Typography } from "@material-ui/core";
import { ref, onValue, query, orderByChild, equalTo } from "firebase/database";
import BlogCategoryInterface from "../../interfaces/entities/BlogCategoryInterface";
import { Helmet } from "react-helmet";
import useFirebaseDB from "../../hooks/useFirebaseDB";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import BlogArticleInterface from "../../interfaces/entities/BlogArticleInterface";
import { objectToArrayConvetor } from "../../util/firebase-object-convertor";
import dateformat from "dateformat";
import { makeFirstLetterInEveryWordUpperCase } from "../../util/title-formater";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: "#f4f6f7",
        paddingTop: 40
      },
      title: {
        color: alpha(theme.palette.common.black, 0.1),
        fontSize: "3rem",
        fontWeight: 700,
        marginBottom: 30
      },
      subTitle: {
        color: theme.palette.common.black,
        fontSize: "1.5rem",
        marginBottom: 30
      },
      grid: {
        marginBottom: 30
      },
      categoryTitle: {
        color: theme.palette.common.black,
        marginBottom: 30,
        fontSize: "2rem"
      },
      categoryPaper: {
        width: "100%",
        backgroundColor: "#e2dbd1",
        marginBottom: 30
      },
      categoryList: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          height: 350,
          overflow: "scroll"
        }
      },
      categoryListText: {
        color: alpha(theme.palette.common.black, 0.5)
      },
      categoryDivider: {
        backgroundColor: alpha(theme.palette.common.black, 0.25)
      },
      categoryLink: {
        textDecoration: "none"
      },
      card: {
        width: "100%",
        backgroundColor: "#e2dbd1"
      },
      cardSkeleton: {
        width: "100%",
        backgroundColor: "#e2dbd1"
      },
      articleTitle: {
        textOverflow: "ellipsis",
        minHeight: "3em",
        maxHeight: "3em",
        lineHeight: "1.5em",
        fontSize: "2em",
        overflow: "hidden"
      },
      articleText: {
        textOverflow: "ellipsis",
        minHeight: "6em",
        maxHeight: "6em",
        lineHeight: "1.5em",
        overflow: "hidden"
      },
      breadcrumbLink: {
        textDecoration: "none",
        color: "#2f89fc",
        "&:hover": {
          color: alpha("#2f89fc", 0.5)
        }
      },
      articleButton: {
        backgroundColor: "#2f89fc",
        color: theme.palette.common.white,
        textTransform: "none",
        width: "50%",
        marginTop: 5,
        marginBottom: 15,
        "&:hover": {
          backgroundColor: alpha("#2f89fc", 0.9)
        }
      }
    })
  );

const Blog: React.FC = () => {
  const styles = useStyles()();

  const { category_id } = useParams<{ category_id?: string }>();

  const { db } = useFirebaseDB();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [page, setPage] = useState<number>(0);
  const [articles, setArticles] = useState<Array<BlogArticleInterface>>([]);
  const [categories, setCategories] = useState<Array<BlogCategoryInterface>>([]);

  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);
  const [articlesLoading, setArticlesLoading] = useState<boolean>(true);

  useEffect(() => {
    setPage(0);
    setArticlesLoading(true);
  }, [category_id]);

  useEffect(() => {
    onValue(ref(db, "blog/categories"), (snapshot) => {
      const data = snapshot.val();

      if (data) {
        setCategories(objectToArrayConvetor<BlogCategoryInterface>(data));
      } else {
        setCategories([]);
      }

      setCategoriesLoading(false);
    });

    let articleRef = query(ref(db, "blog/articles"));

    if (category_id) {
      articleRef = query(ref(db, "blog/articles"), orderByChild("category"), equalTo(category_id));
    }

    onValue(articleRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setArticles(objectToArrayConvetor<BlogArticleInterface>(data).reverse());
      } else {
        setArticles([]);
      }
      setArticlesLoading(false);
    });
  }, [db, category_id]);

  let start = page * 6 - 1;

  if (page === 0) {
    start = 0;
  }

  let end = page * 6 + 5;

  if (page === 0) {
    end = 6;
  }

  const currentArticles = articles.slice(start, end);

  const category = category_id?.split("-").join(" ") ?? "";

  const categoryTitle = makeFirstLetterInEveryWordUpperCase(category);

  return (
    <React.Fragment>
      {category_id ? (
        <Helmet>
          <title>{`Blog Articles On ${categoryTitle}`}</title>
          <meta name="description" content={`Get the all the inclusive articles with regard to ${category} to help you in your day to day life`} />
          <link rel="canonical" href={window.location.origin + "/blog/category/" + category_id} />
          <meta property="og:title" content={`Blog Articles On ${categoryTitle}`} />
          <meta property="og:site_name" content="Purity International Consultancy LTD" />
          <meta property="og:description" content={`Get the all the inclusive articles with regard to ${category} to help you in your day to day life`} />
          <meta property="og:url" content={window.location.origin} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={`${window.location.origin}/articles.jpeg`} />
        </Helmet>
      ) : (
        <Helmet>
          <title>Blog Articles</title>
          <meta name="description" content="Get the all the inclusive articles with regard to various topics to help you in your day to day life" />
          <link rel="canonical" href={window.location.origin + "/blog"} />
          <meta property="og:title" content="Blog Articles" />
          <meta property="og:site_name" content="Purity International Consultancy LTD" />
          <meta property="og:description" content="Get the all the inclusive articles with regard to various topics to help you in your day to day life" />
          <meta property="og:url" content={window.location.origin + "/blog"} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={`${window.location.origin}/articles.jpeg`} />
        </Helmet>
      )}

      <div className={styles.root}>
        <Container maxWidth="lg">
          <Typography variant="h2" className={styles.title}>
            {category_id ? category_id.split("-").join(" ").toUpperCase() : "BLOG ARTICLES"}
          </Typography>
          {!category_id && (
            <Typography variant="h3" className={styles.subTitle}>
              Get all the latest tips for free
            </Typography>
          )}
          <Breadcrumbs>
            <Link color="inherit" to="/" className={styles.breadcrumbLink}>
              Home
            </Link>
            {category_id ? (
              <Link color="inherit" to="/blog" className={styles.breadcrumbLink}>
                Article
              </Link>
            ) : (
              <Typography color="textPrimary">Articles</Typography>
            )}
            {category_id && <Typography color="textPrimary">{categoryTitle}</Typography>}
          </Breadcrumbs>
          <br />
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={9}>
              <Grid container spacing={3} className={styles.grid}>
                {articlesLoading ? (
                  new Array(6).fill(null).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <div className={styles.cardSkeleton}>
                        <Skeleton style={{ marginBottom: 25 }} variant="rect" height={200} />
                        <div style={{ padding: 15 }}>
                          <Skeleton style={{ marginBottom: 10 }} variant="text" width="25%" />
                          <Skeleton style={{ marginBottom: 25 }} variant="rect" height={20} />
                          <Skeleton style={{ marginBottom: 5 }} variant="text" />
                          <Skeleton style={{ marginBottom: 5 }} variant="text" />
                          <Skeleton style={{ marginBottom: 5 }} variant="text" />
                        </div>
                      </div>
                    </Grid>
                  ))
                ) : currentArticles.length === 0 ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h3" align="center" color="textSecondary" style={{ marginTop: 150, marginBottom: 150 }}>
                      No Blog Articles Available
                    </Typography>
                  </Grid>
                ) : (
                  currentArticles.map((article, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card className={styles.card}>
                          <CardActionArea>
                            <CardMedia component="img" alt={article.title} height="200" image={article.image} title={article.title} />
                            <CardContent>
                              <Typography variant="subtitle1" color="textSecondary">
                                {makeFirstLetterInEveryWordUpperCase(article.category.split("-").join(" "))}
                              </Typography>
                              <Typography gutterBottom variant="h3" className={styles.articleTitle}>
                                {article.title}
                              </Typography>
                              <Typography variant="caption" color="textSecondary">
                                {dateformat(article.timestamp, "ddd mmm dd yyyy")}
                              </Typography>
                              <br />
                              <Typography variant="body2" color="textSecondary" component="p" align="justify" className={styles.articleText}>
                                {article.content}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions style={{ justifyContent: "center" }}>
                            <Button
                              className={styles.articleButton}
                              size="large"
                              component={Link}
                              to={`/blog/${article.category}/${article.title
                                .replace(/[^a-zA-Z0-9 ]/g, "")
                                .replace(/ /g, "-")
                                .toLowerCase()}/${article.id}`}>
                              Read More
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })
                )}
                {currentArticles.length > 0 && !articlesLoading && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2} style={{ width: "100%" }} justifyContent="space-between">
                      <Grid item>
                        <Button
                          disabled={page === 0}
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            setPage(page - 1);
                            window.scroll(0, 0);
                          }}>
                          Previous
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={page * 6 + 6 > articles.length}
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            setPage(page + 1);
                            window.scroll(0, 0);
                          }}>
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} sm={12} md={3}>
                <Paper className={styles.categoryPaper}>
                  <Typography variant="h4" className={styles.categoryTitle} align="center">
                    Categories
                  </Typography>
                  <Divider className={styles.categoryDivider} />
                  {categoriesLoading ? (
                    <React.Fragment>
                      {new Array(7).fill(null).map((_, index) => (
                        <Skeleton style={{ marginTop: 25, marginBottom: 25, marginLeft: 10, marginRight: 10 }} key={index} variant="rect" height={30} />
                      ))}
                      <br />
                    </React.Fragment>
                  ) : (
                    <List className={styles.categoryList}>
                      {categories.map((category, index) => (
                        <Link className={styles.categoryLink} key={index} to={`/blog/category/${category.id}`}>
                          <ListItem button className={styles.categoryListText}>
                            <ListItemText primary={category.name} />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  )}
                </Paper>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Blog;
