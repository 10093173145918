import React, { useEffect, useState } from "react";
import { Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Hidden, Drawer, Divider, CssBaseline, AppBar, Toolbar, Typography, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { Lock, Menu, Person, PowerSettingsNew, Public } from "@material-ui/icons";
import DashboardArticles from "./Articles/Articles";
import DashboardChangePassword from "./ChangePassword/ChangePassword";
import Error404Container from "../Error404";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";
import qs from "query-string";

const useStyles = (drawerWidth: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex"
      },
      logo: {
        height: "100px",
        display: "block",
        margin: "auto"
      },
      drawer: {
        [theme.breakpoints.up("sm")]: {
          width: drawerWidth,
          flexShrink: 0
        }
      },
      appBar: {
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth
        }
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          display: "none"
        }
      },
      drawerPaper: {
        width: drawerWidth
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3)
      },
      toolbar: theme.mixins.toolbar
    })
  );

const Dashboard: React.FC = () => {
  const styles = useStyles(300)();

  const history = useHistory();

  const { auth, loggedIn } = useFirebaseAuth();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const menuStyle = (contains: string) => (window.location.pathname.includes(contains) ? { color: "blue" } : {});

  useEffect(() => {
    if (!loggedIn) {
      history.push("/account/login?" + qs.stringify({ continueTo: history.location.pathname }));
    }
  }, [loggedIn, history]);

  const menuSection = (
    <React.Fragment>
      <List>
        <ListItem>
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Logged In As" secondary={auth.currentUser?.email ?? ""} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {[
          { to: "/dashboard/articles", text: "Blog Articles", icon: <Public />, contains: "articles" },
          { to: "/dashboard/change-password", text: "Change Password", icon: <Lock />, contains: "change-password" }
        ].map((element, index) => (
          <ListItem component={Link} to={element.to} button key={index}>
            <ListItemIcon style={menuStyle(element.contains)}>{element.icon}</ListItemIcon>
            <ListItemText primary={element.text} style={menuStyle(element.contains)} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            auth.signOut();
            history.push("/account/login#logged-out-successfully");
          }}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItem>
      </List>
    </React.Fragment>
  );

  return (
    <div className={styles.root}>
      <CssBaseline />
      <AppBar position="fixed" className={styles.appBar}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => setIsDrawerOpen(true)} className={styles.menuButton}>
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            Purity International Consultancy LTD
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={styles.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer container={window.document.body} variant="temporary" anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} classes={{ paper: styles.drawerPaper }} ModalProps={{ keepMounted: true }}>
            {menuSection}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer classes={{ paper: styles.drawerPaper }} variant="permanent" open>
            {menuSection}
          </Drawer>
        </Hidden>
      </nav>
      <main className={styles.content}>
        <div className={styles.toolbar} />
        <Switch>
          <Route exact path="/dashboard">
            <Redirect to="/dashboard/articles" />
          </Route>
          <Route exact path="/dashboard/articles" component={DashboardArticles} />
          <Route exact path="/dashboard/change-password" component={DashboardChangePassword} />
          <Route component={Error404Container} />
        </Switch>
      </main>
    </div>
  );
};

export default Dashboard;
