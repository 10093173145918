import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Container, Paper, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Mail } from "@material-ui/icons";
import DialogModal from "../../components/DialogModal";
import validator from "validator";
import { ADMIN_THEME } from "../../util/theme";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";
import { sendPasswordResetEmail } from "firebase/auth";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: ADMIN_THEME.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      title: {
        color: ADMIN_THEME.titleTextColor
      },
      text: {
        color: theme.palette.text.primary
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: ADMIN_THEME.textField.backgroundColor,
        color: ADMIN_THEME.textField.color
      },
      textFieldIcon: {
        color: ADMIN_THEME.iconColor
      }
    })
  );

const Forgot: React.FC = () => {
  const history = useHistory();

  const styles = useStyles()();

  const { auth, loggedIn } = useFirebaseAuth();

  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const submitForm = () => {
    if (!validator.isEmail(email)) {
      setDialogOptions({ open: true, body: "Email address entered is not valid" });
    } else {
      sendResetPasswordLink();
    }
  };

  const sendResetPasswordLink = useCallback(async () => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, email);
      history.push("/account/login#check-email-for-reset-password-link");
    } catch (error) {
      setDialogOptions({ open: true, body: (error as Error).message });
    } finally {
      setLoading(false);
    }
  }, [auth, history, email]);

  useEffect(() => {
    if (loggedIn) {
      history.push("/");
    }
  }, [loggedIn, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={() => setDialogOptions({ open: false, body: "" })} />
      <Container maxWidth="sm">
        <Grid container alignItems="center" style={{ minHeight: "85vh" }}>
          <Grid item xs={11}>
            <Paper className={styles.paper}>
              <form
                style={{ width: "100%" }}
                onSubmit={(event) => {
                  event.preventDefault();
                  submitForm();
                }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" align="center" className={styles.title}>
                      Forgot Password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center" className={styles.text}>
                      Enter your email address so that we can send you a reset password link to your email
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="email"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      placeholder="Email Address"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Mail className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
                      {loadingIndicator}Reset Password
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} component={Link} to="/account/login">
                      Back To Log In
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Forgot;
