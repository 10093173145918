import { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";

const useFirebaseDB = () => {
  const db = getDatabase();

  const [initialConnection, setInitialConnection] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const [isReConnected, setIsReConnected] = useState<boolean>(false);

  useEffect(() => {
    if (isReConnected) {
      setTimeout(() => {
        setIsReConnected(false);
      }, 3500);
    }
  }, [isReConnected]);

  useEffect(() => {
    onValue(ref(db, ".info/connected"), (snap) => {
      const connected = snap.val() === true;

      if (initialConnection) {
        if (isConnected !== connected) {
          if (isConnected) {
            setIsReConnected(false);
          } else {
            setIsReConnected(true);
          }
          setIsConnected(connected);
        }
      }

      if (connected) {
        setInitialConnection(true);
      }
    });
  }, [db, isConnected, initialConnection]);

  return { db, isConnected, isReConnected };
};

export default useFirebaseDB;
